import React from 'react';
import style from '../style.module.css'

const ReduxSample = ()=>{

    return (
        <div className={`${style.item_content} mt-5 p-4 container-fluid`}>
            <h4 className="text-center">یک مثال اولیه از redux </h4>
        </div>
    )

}

export default ReduxSample;