import React from 'react';
import style from '../style.module.css'

const EditDesc = ()=>{

    return (
        <div className={`${style.item_content} mt-5 p-4 container-fluid`}>
            <h4 className="text-center alert alert-warning">
                توضیحات مربوط به صفحه ویرایش کاربر    
            </h4>
        </div>
    )

}

export default EditDesc;